import { Card, CardBody, CardHeader, Col, Label, Row } from 'reactstrap'
import Chart from 'react-google-charts'
import classnames from "classnames";
import CommonHeader from '../../../../Common/CommonHeader'
import { PieChart } from '../../../../utils/Constant'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import Select, { SingleValue } from "react-select";
import { useEffect, useState } from 'react';
import webservice from "../../../../Service/webservice";
import websocket_webservice from "../../../../Service/websocket_webservice";
import { AesDecrypt, AesEncrypt } from '../../../../Service/crypto';
import { H2 } from '../../../../AbstractElements';
import { getapicount } from '../store/index';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
interface CompanyOption {
    label: string;
    value: string;
}

interface IClientOption {
    label: string;
    value: string;
}

interface File {
    client_id?: string;
    client_name?: string;
    id?: string;
}
interface Client {
    client_id: string;
    client_name: string;
}
interface Count {
    client_id: string;
    count: number;
}

const PieChart2Class = () => {
    const { control } = useForm();
    const dispatch = useDispatch();
    const [selectedClient, setSelectedClient] = useState<string>('All');
    const [selectedClientId, setSelectedClientId] = useState<string>('ALL');
    const [clientOptions, setClientOptions] = useState<IClientOption[]>([]);
    const [Clientnames, setclientname] = useState<Client[]>([]);
    const [Count, setcount] = useState<Count[]>([]);


    // const get_companyname = async () => {
    //     const response = await fetch(webservice + 'api/BulkUpload/companyname', {
    //         method: 'POST',
    //         headers: {
    //             'Content-type': 'application/json; charset=UTF-8',
    //         },
    //     }).then((response) => response.json());

    //     const TempData = AesDecrypt(response);
    //     return TempData;
    // };

    const UserType = localStorage.UserType;
    const Id = localStorage.VendorId;

    const get_companyname = async () => {
        // Prepare the request body based on UserType
        let bodyData = {};
        if (UserType === 'MOS') {
            bodyData = { UserType }; // Send UserType only
        } else if (UserType === 'Vendor') {
            bodyData = { UserType, VendorId: Id }; // Send both UserType and VendorId
        }

        // Make the fetch request
        const response = await fetch(webservice + 'api/Dashboard/getclient', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(bodyData), // Send the request body
        }).then((response) => response.json());

        const TempData = AesDecrypt(response);
        return TempData;
    };

    useEffect(() => {
        const fetchRoleId = async () => {
            try {
                const response = await get_companyname();
                const companyData = response.map((data: any) => ({
                    label: data.CompanyName,
                    value: data.id,
                }));

                // Sort the data alphabetically by label
                const sortedCompanyData = companyData.slice().sort((a: CompanyOption, b: CompanyOption) => a.label.localeCompare(b.label));
                // Add the 'All' option at the beginning of the list
                const allOption = { label: 'All', value: 'ALL' };
                const companyDataWithAll = [allOption, ...sortedCompanyData];

                // Set the client options state
                setClientOptions(companyDataWithAll);
            } catch (error) {
                console.error(error);
            }
        };

        // Fetch the initial data
        fetchRoleId();
        // WebSocket setup
        const socket = new WebSocket(`${websocket_webservice}ws/ClientDropdown/`);

        // socket.onopen = () => console.log('WebSocket connected');
        // socket.onclose = () => console.log('WebSocket closed');
        // socket.onerror = (error) => console.error('WebSocket error:', error);

        socket.onmessage = () => {
            // const change = JSON.parse(event.data);
            // console.log('WebSocket message received:', change);

            // Optionally, re-fetch the data to update the state when a WebSocket message is received
            fetchRoleId();
        };

        // Clean up the WebSocket connection when the component unmounts
        return () => {
            socket.close();
        };
    }, []);

    const handleSelectClientChange = (selectedOption: SingleValue<IClientOption>) => {
        if (selectedOption) {
            setSelectedClient(selectedOption.label);
            setSelectedClientId(selectedOption.value);
            // template()
        } else {
            setSelectedClient('');
            setSelectedClientId('ALL');
        }
    }

    const ViewAuditLog = async () => {
        const Username = localStorage.UserName;
        const Userid = localStorage.id;
        const Clientname = selectedClient;
        const Chartname = 'API Count'
        const Data = { Username, Clientname, Userid, Chartname };


        const EncParams = AesEncrypt(JSON.stringify(Data));
        const response = await fetch(webservice + 'api/Dashboard/auditlog', {
            method: 'POST',
            body: JSON.stringify({ EncParams }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            }
        }).then((response) => response.json());
        return response;
    };

    useEffect(() => {
        let clientid: string | null;

        if (localStorage.UserType === 'MOS') {
            clientid = selectedClientId;
        } else if (localStorage.UserType === 'Vendor') {
            clientid = selectedClientId;
        } else {
            clientid = localStorage.ClientId;
        }

        const fetchData = async () => {
            try {
                const resReceived = await dispatch<any>(getapicount(clientid));
                const dataArrayReceived: File[] = AesDecrypt(resReceived.payload);

                

                // Count occurrences of client_id
                const clientIdCount = dataArrayReceived.reduce((acc: Record<string, number>, item) => {
                    if (item.client_id) {
                        acc[item.client_id] = (acc[item.client_id] || 0) + 1;
                    }
                    return acc;
                }, {});

                // Set the count for each client
                const countsArray = Object.entries(clientIdCount).map(([clientId, count]) => ({
                    client_id: clientId,
                    count: count,
                }));
                setcount(countsArray);

                // Extract unique client names
                const uniqueClients = new Map<string, string>();
                dataArrayReceived.forEach((file) => {
                    if (file.client_id && file.client_name) {
                        uniqueClients.set(file.client_id, file.client_name);
                    }
                });

                // Convert unique clients to array
                const clientNamesArray = Array.from(uniqueClients.entries()).map(([client_id, client_name]) => ({
                    client_id,
                    client_name,
                }));
                setclientname(clientNamesArray);

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
        ViewAuditLog()

        const socket = new WebSocket(`${websocket_webservice}ws/DashboardApicount/`);
        // socket.onopen = () => console.log("WebSocket connected");
        // socket.onclose = () => console.log("WebSocket closed");
        // socket.onerror = (error) => console.error("WebSocket error:", error);

        socket.onmessage = () => {
            fetchData();
        };

        return () => {
            socket.close();
        };
    }, [dispatch, selectedClientId, selectedClient]);

    const baseChartOptions: ApexOptions = {
        chart: {
            height: 70,
            type: 'bar',
            stacked: true,
            sparkline: {
                enabled: true,
            },
        },
        plotOptions: {
            bar: {
                horizontal: true,
                barHeight: '15%',
                colors: {
                    backgroundBarColors: ['#666666'],
                    backgroundBarOpacity: 0.2,
                },
            },
        },
        stroke: {
            width: 0,
        },
        tooltip: {
            enabled: false,
        },
        yaxis: {
            max: 100,
        },
        responsive: [{
            breakpoint: 767,
            options: {
                title: {
                    style: {
                        fontSize: '16px',
                    },
                },
            },
        }],
    };


    const colors = ['#FF5733', '#33FF57', '#3357FF', '#FF33A1', '#F3FF33'];

    return (

        <Card style={{ height: '95%' }}>
            <CardHeader>
                <div className="header-top">
                    <H2>API Transaction</H2>
                    <Col xl={6}>
                        {(localStorage.UserType === 'MOS' || localStorage.UserType === 'Vendor') && (
                            <div style={{ display: 'flex', justifyContent: "flex-end" }}>
                                <Label className="form-label" style={{ fontSize: "110%" }}>
                                    Select Client
                                </Label>
                                <Controller
                                    name="client"
                                    control={control} // control comes from react-hook-form
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            isClearable
                                            options={clientOptions}
                                            classNamePrefix="select"
                                            placeholder="Client Names"
                                            className={classnames("form-control")}
                                            styles={{
                                                control: (styles) => ({
                                                    ...styles,
                                                    width: "100%",
                                                    height: '100%',
                                                    borderColor: "black",
                                                }),
                                                placeholder: (styles) => ({
                                                    ...styles,
                                                    textAlign: "center",
                                                }),
                                            }}
                                            value={selectedClient ? { value: selectedClientId, label: selectedClient } : null}
                                            onChange={(selectedOption) => {
                                                handleSelectClientChange(selectedOption as SingleValue<IClientOption>);
                                                field.onChange(selectedOption); // Update the field's value
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        )}
                    </Col>
                </div>
            </CardHeader>
            <CardBody className="chart-block" >
                <div className="chart-container progress-chart" style={{ overflowY: 'auto', height: '100%' }}>
                    {Clientnames.length === 0 || Count.length === 0 ? (
                        // Display "No Data" message when Clientnames or Count is empty
                        <div style={{ textAlign: 'center', padding: '20px', fontSize: '20px', color: '#000000' }}>
                            No Data
                        </div>
                    ) : (
                        // Render charts when there is data
                        Clientnames.map((client, index) => {
                            const matchedCount = Count.find(countObj => countObj.client_id === client.client_id)?.count || 0;

                            const chartOptions: ApexOptions = {
                                ...baseChartOptions,
                                colors: [colors[index % colors.length]],  // Loop through 5 colors
                                series: [
                                    {
                                        name: client.client_name,
                                        data: [matchedCount],  // Use the matched count for this client
                                    },
                                ],
                                title: {
                                    floating: true,
                                    offsetX: -10,
                                    offsetY: 5,
                                    text: client.client_name,
                                    style: {
                                        fontSize: '18px',
                                        fontFamily: 'Roboto, sans-serif',
                                        fontWeight: 500,
                                    },
                                },
                                subtitle: {
                                    floating: true,
                                    align: 'right',
                                    offsetY: 0,
                                    text: `${matchedCount} API called`,
                                    style: {
                                        fontSize: '14px',
                                    },
                                },
                                xaxis: {
                                    categories: [client.client_name],
                                },
                            };

                            return (
                                <ReactApexChart
                                    key={`progress-${index}`}
                                    id={`progress-${index}`}
                                    options={chartOptions} // Correctly typed options
                                    series={chartOptions.series}
                                    type="bar" // Ensure this is properly typed as well
                                    height={70}
                                />
                            );
                        })
                    )}
                </div>

            </CardBody>
        </Card>

    )
}

export default PieChart2Class