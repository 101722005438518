import React, { useState, useEffect, Fragment } from 'react';
import Select from 'react-select';
import { Card, CardHeader, CardBody, Label, Row, Col, Input, ListGroupItem, Button, UncontrolledTooltip, Table, Container } from 'reactstrap';
import { Controller, useForm } from 'react-hook-form';
import axios from 'axios';

import Breadcrumbs from "../../../CommonElements/Breadcrumbs/Breadcrumbs";
import webservice from '../../../Service/webservice';
import { AesDecrypt, AesEncrypt } from '../../../Service/crypto';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import websocket_webservice from '../../../Service/websocket_webservice'


interface VendorUserAllocationProps { }

interface User {
    id: string;
    content: string;
    newlychecked: boolean;
}
interface IClientOption {
    label: string;
    value: string;
}
interface IvendorOption {
    label: string;
    value: string;
}
const ClientMapping: React.FC<VendorUserAllocationProps> = () => {

    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();

    const [selectedVendor, setSelectedVendor] = useState<IvendorOption | null>(null);
    const [listArr1, setListArr1] = useState<User[]>([]);
    const [listArr2, setListArr2] = useState<User[]>([]);
    const [userList, setUserList] = useState<User[]>([]);
    const [searchValue, setSearchValue] = useState<string>('');
    const [clientOptions, setClientOptions] = useState<IClientOption[]>([]);
    const [vendorOptions, setvendorOptions] = useState<IvendorOption[]>([]);
    const [checkedClients, setCheckedClients] = useState<string[]>([]);
    const [exsitmappedclients, setexsitmappedclients] = useState<User[]>([]);
    const [isClicked, setIsClicked] = useState(false);
    const { control } = useForm();
    const dispatch = useDispatch();

    // useEffect(() => {
    //     // Fetch vendor data
    //     axios.get(webservice + 'api/VendorMapping/VendorMasterUpdate')
    //         .then((response) => {
    //             const data = response.data;
    //             const vendors = data.map((item: any) => ({
    //                 label: item.CompanyName,
    //                 value: item.id,
    //             }));
    //             const sortedVendors = vendors.slice().sort((a: { label: string; }, b: { label: any; }) =>
    //                 a.label.localeCompare(b.label)
    //             );
    //             setvendorOptions(sortedVendors);
    //         })
    //         .catch((error) => {
    //             console.error('Error fetching vendor data:', error);
    //         });
    // }, []);

    useEffect(() => {
        // Function to fetch vendor data
        const fetchVendorData = () => {
            axios.get(webservice + 'api/VendorMapping/VendorMasterUpdate')
                .then((response) => {
                    const data = response.data;
                    const vendors = data.map((item: any) => ({
                        label: item.CompanyName,
                        value: item.id,
                    }));
                    const sortedVendors = vendors.slice().sort((a: { label: string; }, b: { label: any; }) =>
                        a.label.localeCompare(b.label)
                    );
                    setvendorOptions(sortedVendors);
                })
                .catch((error) => {
                    console.error('Error fetching vendor data:', error);
                });
        };

        // Initial fetch of vendor data
        fetchVendorData();

        // WebSocket setup
        const socket = new WebSocket(`${websocket_webservice}ws/VendorDropdown/`);

        // socket.onopen = () => console.log('WebSocket connected');
        // socket.onclose = () => console.log('WebSocket closed');
        // socket.onerror = (error) => console.error('WebSocket error:', error);

        socket.onmessage = () => {
            // const change = JSON.parse(event.data);
            // console.log('WebSocket message received:', change);

            // Re-fetch vendor data when a WebSocket message is received
            fetchVendorData();
        };

        // Clean up the WebSocket connection when the component unmounts
        return () => {
            socket.close();
        };
    }, []);

    // useEffect(() => {
    //     // Fetch client data
    //     axios.get(webservice + 'api/VendorMapping/clientname')
    //         .then((response) => {
    //             const data = response.data;
    //             const decryptedResult = AesDecrypt(data);
    //             const clients = decryptedResult.map((item: any) => ({
    //                 label: item.UserName,
    //                 value: item.id,
    //             }));
    //             const sortedClients = clients.slice().sort((a: { label: string; }, b: { label: any; }) =>
    //                 a.label.localeCompare(b.label)
    //             );
    //             setClientOptions(sortedClients);
    //             setListArr1(sortedClients.map((client: IClientOption) => ({
    //                 id: client.value,
    //                 content: client.label,
    //                 newlychecked: false
    //             })));
    //         })
    //         .catch((error) => {
    //             console.error('Error fetching client data:', error);
    //         });
    // }, []);

    const get_companyname = async () => {
        const response = await fetch(webservice + 'api/BulkUpload/companyname', {
            method: 'POST',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        }).then((response) => response.json());

        const TempData = AesDecrypt(response);
        return TempData;
    };

    // useEffect(() => {
    //     const fetchRoleId = async () => {
    //         const response = await get_companyname();
    //         const res = response.map((data: any) => ({
    //             label: data.CompanyName,
    //             value: data.id,
    //         }));
    //         setClientOptions(res);
    //     };

    //     fetchRoleId();
    // }, [dispatch]);

    useEffect(() => {
        const fetchRoleId = async () => {
            const response = await get_companyname();
            const res = response.map((data: any) => ({
                label: data.CompanyName,
                value: data.id,
            }));
            setClientOptions(res);
        };

        // Fetch the initial data
        fetchRoleId();

        // WebSocket setup
        const socket = new WebSocket(`${websocket_webservice}ws/ClientDropdown/`);

        // socket.onopen = () => console.log('WebSocket connected');
        // socket.onclose = () => console.log('WebSocket closed');
        // socket.onerror = (error) => console.error('WebSocket error:', error);

        socket.onmessage = () => {
            // const change = JSON.parse(event.data);
            // console.log('WebSocket message received:', change);

            // Optionally, re-fetch the data to update the state when a WebSocket message is received
            fetchRoleId();
        };

        // Clean up the WebSocket connection when the component unmounts
        return () => {
            socket.close();
        };
    }, []);

    const handleCheckboxChange = (clientId: string) => {
        const isChecked = checkedClients.includes(clientId);
        if (isChecked) {
            const updatedCheckedClients = checkedClients.filter(id => id !== clientId);
            setCheckedClients(updatedCheckedClients);
            setListArr2(listArr2.filter(user => user.id !== clientId));
        } else {
            const clientToAdd = clientOptions.find(client => client.value === clientId);
            if (clientToAdd) {
                setCheckedClients([...checkedClients, clientId]);
                setListArr2([...listArr2, { id: clientId, content: clientToAdd.label, newlychecked: true }]);
            }
        }
    };

    useEffect(() => {
        if (id) {

            const isChecked = checkedClients.includes(id);
            if (isChecked) {
                const updatedCheckedClients = checkedClients.filter(id => id !== id);
                setCheckedClients(updatedCheckedClients);
                setListArr2(listArr2.filter(user => user.id !== id));
            } else {
                const clientToAdd = clientOptions.find(client => client.value === id);
                if (clientToAdd) {
                    setCheckedClients([...checkedClients, id]);
                    setListArr2([...listArr2, { id: id, content: clientToAdd.label, newlychecked: true }]);
                }
            }
        }
    }, [id, clientOptions])


    const filteredClientOptions = clientOptions.filter(option =>
        option.label.toLowerCase().includes(searchValue.toLowerCase())
    );


    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
    };
    const Userid = localStorage.getItem('id')
    const UserName = localStorage.getItem('UserName')
    const handleSave = () => {
        setIsClicked(true);
        const selectedVendorName = selectedVendor ? selectedVendor.label : '';
        const selectedVendorId = selectedVendor ? selectedVendor.value : '';

        // Check if there are newly checked clients
        const newlyCheckedClients = listArr2.filter(client => client.newlychecked && !exsitmappedclients.some(mappedClient => mappedClient.id === client.id));


        if (newlyCheckedClients.length > 0) {
            const clientIds = newlyCheckedClients.map(client => client.id);
            const clientNames = newlyCheckedClients.map(client => client.content);
            const data = {
                vendor_id: selectedVendorId,
                vendor: selectedVendorName,
                client_id: clientIds,
                client_names: clientNames,
            };
            axios.post(webservice + 'api/VendorMapping/Allocation', data)
                .then(response => {
                    Swal.fire({
                        icon: 'success',
                        title: 'Client Mapped successfully!',
                    }).then(() => {
                        if (id !== undefined) {
                            navigate('/client');

                        } // Reload data after user clicks OK in the SweetAlert popup
                        setListArr2([]);
                        setCheckedClients([]);
                        setexsitmappedclients([]);
                        setIsClicked(false);
                    });
                })
                .catch(error => {
                    console.error('Error saving data:', error);
                    setIsClicked(false);
                });

            const auditData = {
                JsonData: [{
                    field: selectedVendorName,
                    value: clientNames
                }],
                UserName: UserName,
                Userid: Userid
            };
            const encryptedAuditData = AesEncrypt(JSON.stringify(auditData));
            // Send audit log
            axios.post(webservice + 'api/VendorMapping/AllocationAudit', encryptedAuditData)
                .then(auditResponse => {
                })
                .catch(auditError => {
                    console.error('Error saving audit log:', auditError);
                    // Optionally, handle audit log error actions here
                });
        } else {
            // Optionally, you can show a message or handle this case differently
            Swal.fire({
                icon: 'error',
                title: 'Please select clients to save.!',
            }).then(() => {
                setIsClicked(false);

            });
        }
    };
    const handleVendorSelect = (selectedOption: IvendorOption | null) => {
        if (selectedOption) {
            setSelectedVendor(selectedOption);
            const selectedVendorId = selectedOption.value;
            if (id === undefined) {
                fetchData(selectedVendorId);
            }
        } else {
            // Clear the selected vendor and mapped clients
            if (id === undefined) {
            setSelectedVendor(null);
            setListArr2([]);
            setCheckedClients([]);
            setexsitmappedclients([]);
        }
        }
    };

    const fetchData = async (selectedVendorId: string) => {
        try {
            const EncParams = AesEncrypt(JSON.stringify({ selectedVendorId })); // Stringify the object
            const response = await fetch(webservice + 'api/VendorMapping/allocationsclients', {
                method: 'POST',
                body: JSON.stringify({ EncParams }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            });
            const result = await response.json();
            const decryptedResult: Client[] = AesDecrypt(result);
            interface Client {
                client_id: string;
                client: string;
            }
            const clients: User[] = decryptedResult.map((item: Client) => ({
                id: item.client_id,
                content: item.client,
                newlychecked: true
            }));
            setexsitmappedclients(clients);
            setListArr2(clients); // Set the existing mapped clients to listArr2 to show them on the right side
            setCheckedClients(clients.map((client: User) => client.id)); // Check the already mapped clients
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const vendorname = selectedVendor?.label;


    return (
        <Fragment>
            <Container fluid>
                <Breadcrumbs mainTitle="Client Mapped Vendor" title="Client Mapped Vendor" />
                <Card>
                    <CardBody>
                        <Row className='mt-1 mb-50'>
                            <Col xs={6}></Col>
                            <Col md={3} xs={5} className='mt-1 mb-50'>
                                <Label className='form-label' htmlFor='User'>
                                    Select Vendor <span style={{ color: 'red' }}>*</span>
                                </Label>
                                <Controller
                                    control={control}
                                    name='User'
                                    render={({ field }) => (
                                        <Select
                                            isClearable
                                            options={vendorOptions}
                                            classNamePrefix='select'
                                            {...field}
                                            onChange={(selectedOption) => {
                                                setSelectedVendor(selectedOption);
                                                handleVendorSelect(selectedOption);
                                            }}
                                        />
                                    )}
                                    rules={{ required: 'This field is required.' }}
                                />
                            </Col>
                        </Row>
                    </CardBody>
                    <Col lg='2' style={{ marginLeft: '2%' }}>
                        <div className='d-flex align-items-center'>
                            <Label className='form-label'>Search</Label>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Input
                                id='search-client-master'
                                className='ms-50 me-2 w-100'
                                type='text'
                                value={searchValue}
                                onChange={handleSearchChange}
                                placeholder=''
                            />
                        </div>
                    </Col>
                    <CardBody>
                        <Row className='mt-1 mb-50'>
                            <Col md='6' sm='12'>
                                <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th style={{ width: '39%', backgroundColor: "#355876", color: "white" }}>Clients</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredClientOptions.map((item) => (
                                                <tr key={item.value}>
                                                    <td>
                                                        <ListGroupItem
                                                            style={{ cursor: 'pointer', padding: '5px' }}
                                                            onChange={() => {
                                                                handleCheckboxChange(item.value)
                                                            }}
                                                            checked={checkedClients.includes(item.value) || item.value === id}
                                                            disabled={exsitmappedclients.find(client => client.id === item.value) !== undefined}
                                                        >
                                                            <div className='d-flex align-items-center'>
                                                                <div
                                                                    onClick={(e) => {
                                                                        // Prevent row click when clicking the checkbox
                                                                        e.stopPropagation();
                                                                    }}
                                                                >
                                                                    <input
                                                                        type="checkbox"
                                                                        id={item.value}
                                                                        checked={checkedClients.includes(item.value) || item.value === id}
                                                                        onChange={() => {


                                                                            handleCheckboxChange(item.value)

                                                                        }}
                                                                        disabled={exsitmappedclients.find(client => client.id === item.value) !== undefined || item.value === id}
                                                                        style={{ cursor: "pointer" }}
                                                                    />
                                                                    &nbsp;&nbsp;&nbsp;
                                                                    <label htmlFor={item.value}>{item.label}</label>
                                                                </div>
                                                            </div>
                                                        </ListGroupItem>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </Col>
                            <Col md='6' sm='12'>
                                <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th style={{ backgroundColor: "#355876", color: "white" }}>Allocated data for.- {vendorname}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {listArr2.map((item) => (
                                                <tr key={item.id}>
                                                    <td>
                                                        <ListGroupItem>
                                                            <div className='d-flex align-items-center'>
                                                                <div className='col-9'>
                                                                    <h5 className='mt-0'>{item.content}</h5>
                                                                </div>
                                                                <div className='col-3'>
                                                                    {exsitmappedclients.find(client => client.id === item.id) === undefined && id === undefined && (
                                                                        <span
                                                                            style={{ color: "red", cursor: "pointer" }}
                                                                            onClick={() => {
                                                                                const updatedListArr2 = listArr2.filter((el) => el.id !== item.id);
                                                                                setListArr2(updatedListArr2);
                                                                                const updatedCheckedClients = checkedClients.filter((id) => id !== item.id);
                                                                                setCheckedClients(updatedCheckedClients);
                                                                            }}
                                                                        >
                                                                            X
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </ListGroupItem>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </Col>
                        </Row>
                        <Col xs={12} className='text-center mt-2 pt-50'>
                            <Button
                                color='primary'
                                onClick={handleSave}
                                style={isClicked ? { backgroundColor: 'yellow', borderColor: 'orange', color: 'black' } : {}}
                            >
                                Submit
                            </Button>
                        </Col>
                    </CardBody>
                </Card>
            </Container>
        </Fragment>
    );
};

export default ClientMapping;
