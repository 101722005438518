import { Card, CardBody, CardHeader, Col, Label, Row, Table } from "reactstrap";
import { H3 } from "../../../AbstractElements";
import { H4 } from "../../../AbstractElements";
import { useEffect, useState } from "react";
import { AesDecrypt, AesEncrypt } from "../../../Service/crypto";
import webservice from '../../../Service/webservice';
import websocket_webservice from "../../../Service/websocket_webservice";
import Select from 'react-select';
const recentOrdersHeader = [
  "Client Name","Patient Name", "Payer", "Date", "Errors"
]

interface ClientOption {
  label: string;
  value: string;
  key: string;
}

const RecentOrders = () => {

  const [adminoption, setClient] = useState<ClientOption[]>([]);
  const [recentOrdersBody, setrecentOrdersBody] = useState<[]>([]);
  const [ClientName, setClientName] = useState<string>('');
  const [ClientId, setClientId] = useState<string>('');

  const ViewAuditLog = async () => {
    const Username = localStorage.UserName;
    const Userid = localStorage.id;
    const Clientname = ClientName;
    const Chartname = 'Error Chart'
    const Data = { Username, Clientname, Userid, Chartname };


    const EncParams = AesEncrypt(JSON.stringify(Data));
    const response = await fetch(webservice + 'api/Dashboard/auditlog', {
      method: 'POST',
      body: JSON.stringify({ EncParams }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    }).then((response) => response.json());
    return response;
  };

  useEffect(() => {
    const fetchRole = async (Id: any) => {
      const EncParams = AesEncrypt(Id);
      const response = await fetch(webservice + 'api/Dashboard/ClientwiseError', {
        method: 'POST',
        body: JSON.stringify({ EncParams }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      }).then((response) => response.json());
      const TempData = AesDecrypt(response);
      setrecentOrdersBody(TempData)
      return TempData;
    };
    fetchRole(ClientId);
    ViewAuditLog()

    const socket = new WebSocket(`${websocket_webservice}ws/Dashboardpatient/`);
    // socket.onopen = () => console.log("WebSocket connected");
    // socket.onclose = () => console.log("WebSocket closed");
    // socket.onerror = (error) => console.error("WebSocket error:", error);

    socket.onmessage = () => {
      fetchRole(ClientId);
    };

    return () => {
      socket.close();
    };

  }, [ClientId]);

  const get_ClientRoleId = async () => {
    const response = await fetch(webservice + 'api/clientmanagement/get_client', {
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    }).then((response) => response.json());

    const TempData = AesDecrypt(response);
    return TempData;
  };

  useEffect(() => {
    const fetchRoleId = async () => {
      const response = await get_ClientRoleId();
      const res = response.map((data: any) => ({
        label: data.CompanyName,
        value: data.CompanyName,
        key: data._id,

      }));
      const allOption :ClientOption = {
        label: "All",
        value: "All",
        key: '',
      };
      setClient([allOption, ...res]);
    };

    fetchRoleId();
  }, []);

  const handleClientChange = async (selectedOption: any) => {
    if (selectedOption) {
      setClientName(selectedOption.label);
      setClientId(selectedOption.key);
    } else {
      setClientName('');
      setClientId('');

    }
  }

  return (
    
    <Card className="invoice-card" style={{ height: '93%' }}>
      <CardHeader className="pb-0">
        {(localStorage.UserType === 'MOS' || localStorage.UserType === 'Vendor') && (
          <Row>
          <Col xl="4">{ClientId!==''&&(
        <H3>{ClientName} :-</H3>
          )}</Col>
          <Col xl="4"></Col>
          <Col xl="4">
          <Label check>Client<span style={{ color: 'red' }}> * </span></Label>
          <Select
          
            name="Client"
            options={adminoption}
            onChange={(option: any) => { handleClientChange(option); }}
            className="react-select"
            classNamePrefix="select"
          />
          </Col>
          </Row>
        )}

        </CardHeader>
        <CardBody className="transaction-card">
        <div className="table-responsive theme-scrollbar recent-wrapper">
            <Table className="display order-wrapper" id="recent-order">
              <thead >
                <tr>
                  {recentOrdersHeader.map((data, index) => (
                    <th key={index}>{data}</th>
                  ))}
                </tr>
              </thead>
              <tbody >
              {recentOrdersBody.length > 0 ? (
                recentOrdersBody.map((data:any, index) => (
                  <tr key={index}>
                    <td>
                      <div className="d-flex">
                        <div className="flex-grow-1 ms-2">
                          <H4>{data.ClientName}</H4>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex">
                        <div className="flex-grow-1 ms-2">
                          <H4>{data.Patientname}</H4>
                        </div>
                      </div>
                    </td>
                    <td >{data.Payer_name}</td>
                    <td>{data.uploaded_date}</td>
                    <td className={`txt-danger`}>{data.error_message}</td>
                  </tr>
                ))  ) : (
                  <tr>
                    <td style={{ textAlign: 'center' }}>
                      No records to display
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
    
  );
};

export default RecentOrders;
